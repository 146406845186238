import { brandLinks } from "../constants/index";

export interface IDisclaimer {
  description: {
    mobile: JSX.Element;
    desktop: JSX.Element;
  };
}

export const dataSocial = [
  {
    id: "social-1",
    title: "square-facebook",
    icon: "",
    url: brandLinks.facebook,
  },
  {
    id: "social-2",
    title: "Youtube",
    icon: "",
    url: brandLinks.youtube,
  },
  {
    id: "social-3",
    title: "Twitter",
    icon: "𝕏",
    url: brandLinks.twitter,
  },
  {
    id: "social-4",
    title: "Instagram",
    icon: "",
    url: brandLinks.instagram,
  },
];

export const dataDisclaimer: IDisclaimer[] = [
  {
    description: {
      mobile: (
        <>
          <p>A disponibilidade de itens de série,</p>
          <p>opcionais e acessórios pode variar</p>
          <p>de acordo com a versão escolhida.</p>
          <p>Verifique o Monte seu Carro.</p>
          <p>Imagens meramente ilustrativas.</p>
        </>
      ),
      desktop: (
        <>
          <p>
            A disponibilidade de itens de série, opcionais e acessórios pode
            variar de acordo com a versão escolhida.
          </p>
          <p>Verifique o Monte seu Carro. Imagens meramente ilustrativas.</p>
        </>
      ),
    },
  },
];

import styles from "./banner-125.module.scss";
import { FC, useContext } from "react";
import { CTAButton, SectionElement } from "@/components";
import BANNER_125_ANOS_ASSETS from "@/assets/banner-125-anos.asset";
import scssStyles from "@/utils/scssStyles";
import { Context } from "@/context";
import { dataMenuLabels, links } from "@/data/menu.data";

const NAME = "Fiat Strada";
const reference = dataMenuLabels().find((c) => c.slug === "tributo-125-anos");

const Banner125Anos: FC<{ invert?: boolean }> = ({ invert = false }) => {
  const { state } = useContext(Context);
  return (
    <SectionElement
      id="Banner125Anos"
      navReference={reference}
      className={scssStyles([styles.sectionContainer])}
    >
      {state.layout.isDesktop ? (
        <div
          className={scssStyles([
            styles.content,
            invert ? "" : styles.rowInverse,
          ])}
        >
          <div className={scssStyles([styles.column])}>
            <div className={scssStyles([styles.row])}>
              <div className={scssStyles([styles.lettering])}>
                <img
                  alt="lettering"
                  src={BANNER_125_ANOS_ASSETS.desktop.lettering}
                  width="238px"
                />
              </div>
              <div className={styles.couple}>
                <img
                  alt="casal"
                  src={BANNER_125_ANOS_ASSETS.desktop.couple}
                  width="356px"
                />
              </div>
            </div>

            <div className={styles.model}>
              <img
                alt="model"
                src={BANNER_125_ANOS_ASSETS.desktop.model}
                width="600px"
              />
            </div>
          </div>

          {/* COLUNA 2 */}

          <div className={scssStyles([styles.column, styles.contentText])}>
            <div className={styles.logo}>
              <img
                alt="logo"
                src={BANNER_125_ANOS_ASSETS.desktop.logo}
                width="505px"
              />
            </div>

            <h2>CELEBRE OS 125 ANOS da FIAT</h2>

            <p>
              Nada melhor para comemorar o aniversário da Fiat do que uma edição
              especial do <strong>{NAME}</strong> com Kit opcional Tributo 125
              anos. Aproveite para garantir o seu!
            </p>

            <div className={styles.cta}>
              <CTAButton
                href={links.banne_125_anos}
                text="Saiba mais"
                title="Saiba mais"
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={scssStyles([
            styles.content,
            invert ? "" : styles.rowInverse,
          ])}
        >
          {/* ____________________MOBILE_________________________ */}
          <div className={styles.logo}>
            <img
              alt="logo"
              src={BANNER_125_ANOS_ASSETS.desktop.logo}
              width="100%"
              height="100px"
            />
          </div>
          <div className={styles.couple}>
            <img
              alt="casal"
              src={BANNER_125_ANOS_ASSETS.desktop.couple}
              width="233px"
            />
          </div>
          <div className={scssStyles([styles.lettering])}>
            <img
              alt="lettering"
              src={BANNER_125_ANOS_ASSETS.desktop.lettering}
              width="238px"
            />
          </div>

          <div className={styles.model}>
            <img
              alt="model"
              src={BANNER_125_ANOS_ASSETS.desktop.model}
              width="450px"
            />
          </div>

          <div className={scssStyles([styles.column, styles.contentText])}>
            <h2>CELEBRE OS 125 ANOS da FIAT</h2>

            <p>
              Nada melhor para comemorar o aniversário da Fiat do que uma edição
              especial do {NAME} com Kit opcional Tributo 125 anos. <br />
              Aproveite para garantir o seu!
            </p>

            <div className={styles.cta}>
              <CTAButton
                href={links.banne_125_anos}
                text="Saiba mais"
                title="Saiba mais"
              />
            </div>
          </div>
        </div>
      )}
    </SectionElement>
  );
};

export default Banner125Anos;

import scssStyles from '@/utils/scssStyles';
import Image from 'next/image';
import React, { useContext, useEffect, useState } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ui } from '@/assets';
import { CTAButton } from '@/components';
import { Context } from '@/context';
import {
  COMPRE_TITLE,
  MONTE_TITLE,
  dataMenuLabels,
  links,
} from '@/data/menu.data';
import DataLayer from '@/utils/DataLayer';
import formatString from '@utils/formatString';
import { PREFIX } from '@utils/imagePrefixes';
import styles from './version.module.scss';

SwiperCore.use([Navigation]);

interface colors {
  haxCode: string;
  colorName: string;
  colorNameFormatted: string;
  pathSrc: string;
  colorActive: boolean;
}

export interface Car {
  id: number;
  mvsCode: string;
  slug: string;
  model: {
    shortname: string;
    fullname: string;
  };
  optionals: {
    title: string;
    fullPath: string;
    fullPath2x: string;
    fullPath3x: string;
  }[];
  colors: colors[];
  price: number;
  path: string;
  versionCtaButton: {
    id: string;
    label: string;
    url: string;
    backgroundColor: string;
    color: string;
    download?: boolean;
    excludeArrow?: boolean;
    iconCode?: string;
  }[];
}

interface CarInfoProps {
  car?: Car;
}

export const getCompreLink = (code: string) => {
  return `https://www.fiat.com.br/compre/fiat-strada.html?mvs=${code}&year=2025`;
};

export const cars: Car[] = [
  {
    id: 0,
    mvsCode: '281BMY1',
    slug: 'ultra-turbo-at',
    model: {
      shortname: 'Ultra Turbo AT',
      fullname: 'Ultra Turbo AT',
    },
    optionals: [
      {
        title: 'Motor Turbo 130 cv',
        fullPath: `${PREFIX}Versions/thumbs/ultra-turbo-at/feature-fiat-strada-ultra-motor-turbo-130cv.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/ultra-turbo-at/feature-fiat-strada-ultra-motor-turbo-130cv@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/ultra-turbo-at/feature-fiat-strada-ultra-motor-turbo-130cv@3x.webp`,
      },
      {
        title: 'Design exclusivo Ultra',
        fullPath: `${PREFIX}Versions/thumbs/ultra-turbo-at/fiat-strada-design-exclusivo-ultra.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/ultra-turbo-at/fiat-strada-design-exclusivo-ultra@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/ultra-turbo-at/fiat-strada-design-exclusivo-ultra@3x.webp`,
      },
      {
        title: 'Wireless Charger',
        fullPath: `${PREFIX}Versions/thumbs/ultra-turbo-at/fiat-strada-wireless-charger.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/ultra-turbo-at/fiat-strada-wireless-charger@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/ultra-turbo-at/fiat-strada-wireless-charger@3x.webp`,
      },
    ],
    colors: [],
    price: 0,
    path: `${PREFIX}Versions/ultra-turbo-at/f4f4f4.webp`,
    versionCtaButton: [
      {
        id: 'ctaButton-0',
        label: MONTE_TITLE,
        url:`${links.monte}?mvs=281BMY1&year=2025#versao`,
        backgroundColor: '#FFFFFF',
        color: '#000000',
        excludeArrow: false,
      },
      {
        id: 'ctaButton-1',
        label: COMPRE_TITLE,
        url: getCompreLink('281BMY1'),
        backgroundColor: '#1C1C1C',
        color: '#FFFFFF',
        excludeArrow: false,
      },
    ],
  },
  {
    id: 1,
    mvsCode: '281BNY1',
    slug: 'ranch-turbo-at',
    model: {
      shortname: 'Ranch Turbo AT',
      fullname: 'Ranch Turbo AT',
    },
    optionals: [
      {
        title: 'Motor Turbo 130 cv',
        fullPath: `${PREFIX}Versions/thumbs/ranch-turbo-at/feature-fiat-strada-ultra-motor-turbo-130cv.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/ranch-turbo-at/feature-fiat-strada-ultra-motor-turbo-130cv@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/ranch-turbo-at/feature-fiat-strada-ultra-motor-turbo-130cv@3x.webp`,
      },
      {
        title: 'Design exclusivo ”Ranch”',
        fullPath: `${PREFIX}Versions/thumbs/ranch-turbo-at/fiat-strada-design-exclusivo-ranch.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/ranch-turbo-at/fiat-strada-design-exclusivo-ranch@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/ranch-turbo-at/fiat-strada-design-exclusivo-ranch@3x.webp`,
      },
      {
        title: 'Ar-condicionado automático digital',
        fullPath: `${PREFIX}Versions/thumbs/ranch-turbo-at/fiat-strada-condicionado-automatico-digital.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/ranch-turbo-at/fiat-strada-condicionado-automatico-digital@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/ranch-turbo-at/fiat-strada-condicionado-automatico-digital@3x.webp`,
      },
    ],
    colors: [],
    price: 0,
    path: `${PREFIX}Versions/ranch-turbo-at/f4f4f4.webp`,
    versionCtaButton: [
      {
        id: 'ctaButton-0',
        label: MONTE_TITLE,
        url:`${links.monte}?mvs=281BNY1&year=2025#versao`,
        backgroundColor: '#FFFFFF',
        color: '#000000',
        excludeArrow: false,
      },
      {
        id: 'ctaButton-1',
        label: COMPRE_TITLE,
        url: getCompreLink('281BNY1'),
        backgroundColor: '#1C1C1C',
        color: '#FFFFFF',
        excludeArrow: false,
      },
    ],
  },
  {
    id: 2,
    mvsCode: '281BLU1',
    slug: 'volcano-at',
    model: {
      shortname: 'Volcano AT',
      fullname: 'Volcano 1.3 AT',
    },
    optionals: [
      {
        title: 'Faróis em LED',
        fullPath: `${PREFIX}Versions/thumbs/volcano-at/fiat-strada-farois-led.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/volcano-at/fiat-strada-farois-led@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/volcano-at/fiat-strada-farois-led@3x.webp`,
      },
      {
        title: 'Câmbio Automático CVT',
        fullPath: `${PREFIX}Versions/thumbs/volcano-at/fiat-strada-cambio-automatico-cvt.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/volcano-at/fiat-strada-cambio-automatico-cvt@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/volcano-at/fiat-strada-cambio-automatico-cvt@3x.webp`,
      },
      {
        title: 'Rodas de liga-leve 16”',
        fullPath: `${PREFIX}Versions/thumbs/volcano-at/fiat-strada-rodas-liga-leve-16.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/volcano-at/fiat-strada-rodas-liga-leve-16@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/volcano-at/fiat-strada-rodas-liga-leve-16@3x.webp`,
      },
    ],
    colors: [],
    price: 0,
    path: `${PREFIX}Versions/volcano-at/f4f4f4.webp`,
    versionCtaButton: [
      {
        id: 'ctaButton-0',
        label: MONTE_TITLE,
        url:`${links.monte}?mvs=281BLU1&year=2025#versao`,
        backgroundColor: '#FFFFFF',
        color: '#000000',
        excludeArrow: false,
      },
      {
        id: 'ctaButton-1',
        label: COMPRE_TITLE,
        url: getCompreLink('281BLU1'),
        backgroundColor: '#1C1C1C',
        color: '#FFFFFF',
        excludeArrow: false,
      },
    ],
  },
  {
    id: 3,
    mvsCode: '281BLP1',
    slug: 'volcano',
    model: {
      shortname: 'Volcano',
      fullname: 'Volcano 1.3 CD',
    },
    optionals: [
      {
        title: 'Faróis em LED',
        fullPath: `${PREFIX}Versions/thumbs/volcano/fiat-strada-farois-led.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/volcano/fiat-strada-farois-led@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/volcano/fiat-strada-farois-led@3x.webp`,
      },
      {
        title: 'Sensor traseiro',
        fullPath: `${PREFIX}Versions/thumbs/volcano/fiat-strada-sensor-traseiro.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/volcano/fiat-strada-sensor-traseiro@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/volcano/fiat-strada-sensor-traseiro@3x.webp`,
      },
      {
        title: 'Central multimídia de 7”',
        fullPath: `${PREFIX}Versions/thumbs/volcano/fiat-strada-central-multimidia-7.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/volcano/fiat-strada-central-multimidia-7@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/volcano/fiat-strada-central-multimidia-7@3x.webp`,
      },
    ],
    colors: [],
    price: 0,
    path: `${PREFIX}Versions/volcano/f4f4f4.webp`,
    versionCtaButton: [
      {
        id: 'ctaButton-0',
        label: MONTE_TITLE,
        url:`${links.monte}?mvs=281BLP1&year=2025#versao`,
        backgroundColor: '#FFFFFF',
        color: '#000000',
        excludeArrow: false,
      },
      {
        id: 'ctaButton-1',
        label: COMPRE_TITLE,
        url: getCompreLink('281BLP1'),
        backgroundColor: '#1C1C1C',
        color: '#FFFFFF',
        excludeArrow: false,
      },
    ],
  },
  {
    id: 4,
    mvsCode: '281BKP1',
    slug: 'freedom',
    model: {
      shortname: 'Freedom',
      fullname: 'Freedom 1.3 CD',
    },
    optionals: [
      {
        title: 'Cabine dupla',
        fullPath: `${PREFIX}Versions/thumbs/freedom/fiat-strada-cabine-dupla.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/freedom/fiat-strada-cabine-dupla@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/freedom/fiat-strada-cabine-dupla@3x.webp`,
      },
      {
        title: 'Direção elétrica',
        fullPath: `${PREFIX}Versions/thumbs/freedom/fiat-strada-direcao-eletrica.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/freedom/fiat-strada-direcao-eletrica@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/freedom/fiat-strada-direcao-eletrica@3x.webp`,
      },
      {
        title: '4 Airbags',
        fullPath: `${PREFIX}Versions/thumbs/freedom/fiat-strada-4-airbags.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/freedom/fiat-strada-4-airbags@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/freedom/fiat-strada-4-airbags@3x.webp`,
      },
    ],
    colors: [],
    price: 0,
    path: `${PREFIX}Versions/freedom/f4f4f4.webp`,
    versionCtaButton: [
      {
        id: 'ctaButton-0',
        label: MONTE_TITLE,
        url:`${links.monte}?mvs=281BKP1&year=2025#versao`,
        backgroundColor: '#FFFFFF',
        color: '#000000',
        excludeArrow: false,
      },
      {
        id: 'ctaButton-1',
        label: COMPRE_TITLE,
        url: getCompreLink('281BKP1'),
        backgroundColor: '#1C1C1C',
        color: '#FFFFFF',
        excludeArrow: false,
      },
    ],
  },
  {
    id: 5,
    mvsCode: '281AKP1',
    slug: 'freedom-cp',
    model: {
      shortname: 'Freedom CP',
      fullname: 'Freedom 1.3',
    },
    optionals: [
      {
        title: 'Ar-condicionado',
        fullPath: `${PREFIX}Versions/thumbs/freedom-cp/fiat-strada-travas-vidros-eletricas.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/freedom-cp/fiat-strada-travas-vidros-eletricas@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/freedom-cp/fiat-strada-travas-vidros-eletricas@3x.webp`,
      },
      {
        title: 'Direção elétrica',
        fullPath: `${PREFIX}Versions/thumbs/freedom-cp/fiat-strada-direcao-eletrica.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/freedom-cp/fiat-strada-direcao-eletrica@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/freedom-cp/fiat-strada-direcao-eletrica@3x.webp`,
      },
      {
        title: 'Capota marítima',
        fullPath: `${PREFIX}Versions/thumbs/freedom-cp/fiat-strada-capota-maritima.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/freedom-cp/fiat-strada-capota-maritima@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/freedom-cp/fiat-strada-capota-maritima@3x.webp`,
      },
    ],
    colors: [],
    price: 0,
    path: `${PREFIX}Versions/freedom-cp/f4f4f4.webp`,
    versionCtaButton: [
      {
        id: 'ctaButton-0',
        label: MONTE_TITLE,
        url:`${links.monte}?mvs=281AKP1&year=2025#versao`,
        backgroundColor: '#FFFFFF',
        color: '#000000',
        excludeArrow: false,
      },
      {
        id: 'ctaButton-1',
        label: COMPRE_TITLE,
        url: getCompreLink('281AKP1'),
        backgroundColor: '#1C1C1C',
        color: '#FFFFFF',
        excludeArrow: false,
      },
    ],
  },
  {
    id: 6,
    mvsCode: '281AJP1',
    slug: 'endurance',
    model: {
      shortname: 'Endurance',
      fullname: 'Endurance 1.3',
    },
    optionals: [
      {
        title: 'Motor 1.3 Firefly 107 cv',
        fullPath: `${PREFIX}Versions/thumbs/endurance/fiat-strada-motor-13-firefly-107cv.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/endurance/fiat-strada-motor-13-firefly-107cv@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/endurance/fiat-strada-motor-13-firefly-107cv@3x.webp`,
      },
      {
        title: 'Capacidade de carga de 720 kg',
        fullPath: `${PREFIX}Versions/thumbs/endurance/fiat-strada-capacidade-carga-720kg.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/endurance/fiat-strada-capacidade-carga-720kg@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/endurance/fiat-strada-capacidade-carga-720kg@3x.webp`,
      },
      {
        title: 'Ar-condicionado',
        fullPath: `${PREFIX}Versions/thumbs/endurance/fiat-strada-ar-condicionado.webp`,
        fullPath2x: `${PREFIX}Versions/thumbs/endurance/fiat-strada-ar-condicionado@2x.webp`,
        fullPath3x: `${PREFIX}Versions/thumbs/endurance/fiat-strada-ar-condicionado@3x.webp`,
      },
    ],
    colors: [],
    price: 0,
    path: `${PREFIX}Versions/endurance/f4f4f4.webp`,
    versionCtaButton: [
      {
        id: 'ctaButton-0',
        label: MONTE_TITLE,
        url:`${links.monte}?mvs=281AJP1&year=2025#versao`,
        backgroundColor: '#FFFFFF',
        color: '#000000',
        excludeArrow: false,
      },
      {
        id: 'ctaButton-1',
        label: COMPRE_TITLE,
        url: getCompreLink('281AJP1'),
        backgroundColor: '#1C1C1C',
        color: '#FFFFFF',
        excludeArrow: false,
      },
    ],
  },
];

interface ColorButtonsProps {
  activeCarIndex: number;
  activeColorIndex?: number;
  setActiveColorIndex?: (colorIndex: number) => void;
}

const ColorChanger: React.FC<ColorButtonsProps> = ({
  activeCarIndex,
  activeColorIndex,
  setActiveColorIndex,
}) => {
  const { state, dispatch } = useContext(Context);

  const handleColorClick = (
    colorIndex: number,
    colorNameFormatted: string,
    colorName: string,
    colorCode: string
  ): void => {
    DataLayer.clickEvent({
      element: `${colorNameFormatted}`,
      elementCategory: 'icone',
      pageSection: 'versoes',
      pageSubsection: `${cars[activeCarIndex].slug}`,
    });
    dispatch({
      type: 'SET_COLOR_ACTIVE',
      payload: {
        colorActive: colorNameFormatted,
      },
    });
    cars[
      activeCarIndex
    ].path = `${PREFIX}Versions/${cars[activeCarIndex].slug}/${colorCode}.webp`;
    dispatch({
      type: 'SET_VERSION_PHOTO_SRC',
      payload: {
        versionPhotoSrc: `${PREFIX}Versions/${cars[activeCarIndex].slug}/${colorCode}.webp`,
      },
    });
  };
  return (
    <div className={styles.colorsContainer}>
      <p>
        {state.layout.isDesktop
          ? 'Selecione a cor da sua Fiat Strada'
          : 'Disponíveis nas cores'}
      </p>
      <div className={styles.colors}>
        <nav>
          {cars[activeCarIndex].colors.map((color, i) => {
            const active =
              state.layout.colorActive === color.colorNameFormatted;
            return (
              <button
                className={active ? styles.active : ' '}
                key={i}
                onClick={() => {
                  const setFalseColor = cars[activeCarIndex].colors.findIndex(
                    (value) => value.colorActive
                  );
                  cars[activeCarIndex].colors[setFalseColor].colorActive =
                    false;
                  color.colorActive = true;

                  handleColorClick(
                    i,
                    color.colorNameFormatted,
                    color.colorName,
                    color.haxCode
                  );
                }}
              >
                <span
                  className={styles.currCircleColor}
                  style={{
                    background: `radial-gradient(circle at 10px 14px, #${color.haxCode} 9px, #${color.haxCode} 10px)`,
                  }}
                  title={color.colorName}
                />
                <span className={styles.currTextColor}>{color.colorName}</span>
              </button>
            );
          })}
        </nav>
      </div>
    </div>
  );
};

const CarInfo: React.FC<CarInfoProps> = ({ car }) => {
  const { state, dispatch } = useContext(Context);

  const availableColors = async (mvsCode: string) => {
    const dataColor = await fetch(
      `https://part-service.k8s.fcalatam.com.br/v2/color?mvsCode=${mvsCode}&year=2025&market=pt-BR`
    );
    const colorResponse = await dataColor.json();
    const dataPrice = await fetch(
      `https://price-service.k8s.fcalatam.com.br/price?mvsCode=${mvsCode}&year=2025&country=BR&state=SP&excludeParts=true`
    );
    const priceResponse = await dataPrice.json();

    cars.map((item) => {
      if (item.mvsCode === mvsCode) {
        colorResponse.map((value: { rgb: string; name: string }, i: number) => {
          item.colors.push({
            colorName: value.name,
            haxCode: value.rgb,
            colorNameFormatted: formatString(value.name),
            pathSrc: `${PREFIX}Versions/strada/${formatString(
              value.name
            )}.webp`,
            colorActive: formatString(value.name) === 'branco-banchisa',
          });
        });
        item.price = priceResponse.price.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
        });
      }
    });

    dispatch({
      type: 'SET_REQUEST_FINISHED',
      payload: { requestFinished: true },
    });

    return dataColor;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(
        `https://mvs-service.k8s.fcalatam.com.br/mvs?brand=FIAT&model=281&year=2025`
      );
      const json = await data.json();

      json.map((item: { code: string }) => {
        availableColors(item.code);
      });
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <div>
      <div key={car?.id} className={styles.info}>
        {!state.layout.isDesktop && state.layout.requestFinished && (
          <ColorChanger activeCarIndex={car?.id ? car.id : 0} />
        )}
        <div className={styles.optional}>
          <div className={styles.title}>
            <h3>
              <span>Fiat Strada</span>
              {car?.model.fullname}
            </h3>
          </div>
          <ul className={styles.thumbs}>
            {car?.optionals.map((optional, index) => (
              <li key={index}>
                <div className={styles.thumbContainer}>
                  <div className={styles.imageContainer}>
                    <Image
                      src={optional.fullPath2x}
                      alt={'thumb ' + index + ' ' + optional.title}
                      title={optional.title}
                      width={312}
                      height={264}
                    />
                  </div>
                  <div className={styles.titleContainer}>
                    <h4>{optional.title}</h4>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {state.layout.isDesktop && state.layout.requestFinished && (
          <ColorChanger activeCarIndex={car?.id ? car.id : 0} />
        )}
        <div className={styles.price}>
          {state.layout.requestFinished && (
            <div className={styles.payment}>
              <p>A partir de</p>
              <p>
                <strong>R$ {car?.price ? car.price : '- - '}</strong>
              </p>
            </div>
          )}

          <div className={styles.ctas}>
            {car?.versionCtaButton.map((item) => {
              return (
                <div className={styles.ctaLinks} key={`menu-cta-${item.id}`}>
                  <CTAButton
                    href={item.url}
                    className={scssStyles([
                      styles.ctaButton,
                      item.id === 'ctaButton-1' ? styles.interest : '',
                    ])}
                    text={item.label}
                    width={'100%'}
                    title={item.label}
                    download={item.download}
                    excludeArrow={item.excludeArrow}
                    iconCode={item.iconCode}
                    handleClick={() => {
                      DataLayer.clickEvent({
                        element: `${item.label}`,
                        elementCategory: 'icone',
                        pageSection: 'conteudo',
                        pageSubsection: 'versoes',
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Carousel: React.FC = () => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const { state, dispatch } = useContext(Context);
  const reference = dataMenuLabels().find((c) => c.slug === 'versoes');
  const [initialIndex, setInitialIndex] = useState<number>(0);
  const [thumbSwiper, setThumbSwiper] = useState<SwiperCore>();

  useEffect(() => {
    if (
      thumbSwiper &&
      swiperInstance &&
      typeof thumbSwiper?.activeIndex === 'number' &&
      typeof swiperInstance?.activeIndex === 'number'
    ) {
      thumbSwiper.slideTo(swiperInstance.activeIndex);
    }
  }, [
    thumbSwiper,
    thumbSwiper?.activeIndex,
    swiperInstance,
    swiperInstance?.activeIndex,
  ]);

  useEffect(() => {
    const swiperTimer = setTimeout(() => {
      const index = cars.findIndex(
        (c) => c.slug === state.layout.currentVersion.slug
      );
      if (index) {
        setInitialIndex(index);
        swiperInstance?.slideTo(index);
      } else {
        setInitialIndex(1);
      }
    }, 100);

    if (state.layout.galleryExpanded) {
      swiperInstance?.keyboard.disable();
    } else {
      swiperInstance?.keyboard.enable();
    }

    return () => {
      clearTimeout(swiperTimer);
    };
  }, [
    initialIndex,
    activeIndex,
    dispatch,
    state.layout.currentVersion,
    state.layout.galleryExpanded,
    swiperInstance,
    swiperInstance?.keyboard,
  ]);

  useEffect(() => {
    const activeColor = cars[activeIndex].colors.filter(
      (value) => value.colorActive
    );
    dispatch({
      type: 'SET_COLOR_ACTIVE',
      payload: {
        colorActive: activeColor[0]?.colorNameFormatted,
      },
    });

    dispatch({
      type: 'SET_VERSION_PHOTO_SRC',
      payload: { versionPhotoSrc: cars[activeIndex]?.path },
    });
  }, [activeIndex, dispatch]);

  return (
    <section className={styles.versions}>
      <span className={styles.bars}></span>
      <div className={styles.container}>
        <div className={styles.title}>
          <h2>
            Descubra qual <span>Strada</span> combina com você
          </h2>
        </div>
        <nav className={styles.carNames}>
          {state.layout.isDesktop ? (
            cars?.map((version, index) => {
              return (
                <button
                  key={`thumb-${version.id}`}
                  title={version.model.fullname}
                  className={activeIndex === index ? styles.active : ''}
                  onClick={() => {
                    DataLayer.clickEvent({
                      element: `${version.model.fullname}`,
                      elementCategory: 'card',
                      pageSection: 'conteudo',
                      pageSubsection: 'versoes',
                    });
                    swiperInstance?.slideTo(index);
                  }}
                >
                  {version.model.fullname}
                </button>
              );
            })
          ) : (
            <Swiper
              onSwiper={(e) => {
                setThumbSwiper(e);
              }}
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.activeIndex);
              }}
              controller={thumbSwiper ? { control: thumbSwiper } : undefined}
              spaceBetween={20}
              slidesPerView={3}
              centeredSlides={true}
              loop={false}
              allowTouchMove={false}
            >
              {cars?.map((version, index) => {
                return (
                  <SwiperSlide key={index}>
                    <button
                      key={`thumb-${version.id}`}
                      title={version.model.fullname}
                      className={activeIndex === index ? styles.active : ''}
                      onClick={() => {
                        DataLayer.clickEvent({
                          element: `${version.model.fullname}`,
                          elementCategory: 'card',
                          pageSection: 'conteudo',
                          pageSubsection: 'versoes',
                        });
                        swiperInstance?.slideTo(index);
                      }}
                    >
                      {version.model.fullname}
                    </button>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </nav>
      </div>
      <Swiper
        loop={false}
        onSwiper={setSwiperInstance}
        onSlideChange={(swiper) => {
          const selectedVersion = cars[swiper.activeIndex];
          dispatch({
            type: 'SET_CURRENT_VERSION',
            payload: {
              currentVersion: selectedVersion,
            },
          });
          dispatch({
            type: 'SET_CURRENTLY_VISIBLE_NAV',
            payload: {
              view: reference,
              suffix: selectedVersion.slug,
            },
          });
          setActiveIndex(swiper.activeIndex);
        }}
        pagination={false}
        spaceBetween={20}
        slidesPerView={state.layout.isDesktop ? 2 : 1.3}
        centeredSlides={true}
        initialSlide={initialIndex}
      >
        {state.layout.requestFinished &&
          cars.map((car, i) => {
            return (
              <SwiperSlide key={car.id}>
                <div className={styles.carousel}>
                  <Image
                    className={styles.images}
                    height={400}
                    width={661}
                    src={
                      activeIndex === i
                        ? state.layout.versionPhotoSrc
                        : car.path
                    }
                    alt={`${car.model.fullname}`}
                    title={`${car.model.fullname}`}
                    aria-label={`${car.model.fullname}`}
                    priority={activeIndex === i}
                  />
                </div>
              </SwiperSlide>
            );
          })}

        <button
          className={styles.btnPrev}
          onClick={() => {
            DataLayer.clickEvent({
              element: 'anterior',
              elementCategory: 'icone',
              pageSection: 'conteudo',
              pageSubsection: 'versoes',
            });
            swiperInstance?.slidePrev();
          }}
          type="button"
        >
          <Image
            src={ui.arrowPrev}
            alt="Imagem anterior"
            width={11}
            height={17}
          />
        </button>
        <button
          className={styles.btnNext}
          onClick={() => {
            DataLayer.clickEvent({
              element: 'proximo',
              elementCategory: 'icone',
              pageSection: 'conteudo',
              pageSubsection: 'versoes',
            });
            swiperInstance?.slideNext();
          }}
          type="button"
        >
          <Image
            src={ui.arrowNext}
            alt="Próxima Imagem"
            width={11}
            height={17}
          />
        </button>
      </Swiper>
      <CarInfo car={cars[activeIndex]} />
    </section>
  );
};

export default Carousel;
